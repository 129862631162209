import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 获取开票相关信息
export const ivoiceTasks = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/PrintInvoiceTaskList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取开票明细
export const TaskDetailList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/PrintInvoiceTaskDetailList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查询发票图片
export const PrintInvoiceInfoList = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Invoice/PrintInvoiceInfoList?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 导出开票列表
export const DownloadPrintInvoiceTaskList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/DownloadPrintInvoiceTaskList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取结算单列表
export const GetInvoiceTaskList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/GetInvoiceTaskList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}